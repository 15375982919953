::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(122,153,217)),
        color-stop(0.72, rgb(73,125,189)),
        color-stop(0.86, rgb(28,58,148)));
}

.glass-box{
	/* background: rgba(255, 255, 255, 0.2);
	border-radius: 15px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(9.6px);
	-webkit-backdrop-filter: blur(9.6px);
	border: 1px solid rgba(255, 255, 255, 0.3); */

	/* backdrop-filter: blur(2px); */
	/* -webkit-backdrop-filter: blur(10px); */
	/* border: 1px solid rgba(255, 255, 255, 0.4); */
	/* background: rgba(255, 255, 255, 0.33);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */


	/* backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%); */
	/* background-color: rgba(255, 255, 255, 0.5); */
	background-color: #353767;
	border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.hidden{
	display: none;
}

.w-100{
	width:100%;
}

.App{
	background-color: #282b4c;
    /* background:url('../images/background/1.png') no-repeat right center; */
    background-size: cover; /* for IE9+, Safari 4.1+, Chrome 3.0+, Firefox 3.6+ */
    -webkit-background-size: cover; /* for Safari 3.0 - 4.0 , Chrome 1.0 - 3.0 */
    -moz-background-size: cover; /* optional for Firefox 3.6 */ 
    -o-background-size: cover; /* for Opera 9.5 */
}

.nav-header{    
    background-color:#282b4c;
    border-bottom: 1px solid #0f0f0f24;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

button{
	/* background-color: #10d2e1!important; */
}

.MuiBackdrop-root{
	background: linear-gradient(75deg, rgba(212, 217, 210, 0) 0%, rgba(212, 217, 210, 0) 100%);
}