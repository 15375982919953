
.order-item .img-container{
    width:33%;
}

.order-item .detail-container{
    width:64%;
    margin:1%;
}

.order-item .calorie-count{
    font-size:0.85rem;
}

.order-item .nutrition-value{
    font-size:0.75rem;
    text-align: center;
}

.order-item .nutrition-label{
    font-size:0.7rem;
    text-align: center;
}

.order-item .nutrition-detail{
    width:100%;
    /* overflow-x:scroll; */
    height:50px;
    padding:4px;
}


.order-item .nutrition-detail li{
    min-width:26%;
    text-align:center;
    margin: 0!important;
    padding: 0!important;
}

.order-item .nutrition-help-text{
    font-size:0.6rem;
}