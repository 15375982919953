
 .top-spacer {
    height: 50vh;
  }
  
  .bottom-spacer {
    height: 0vh;
  }
  
  .sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }

  .header {
    display: flex;
    justify-content: center;
    background-color: #282b4c;
    /* padding-bottom:2vh; */
    /* background:url('../images/background/1.png') no-repeat; */
  }

  .header_link {
    background-color: transparent;
    padding: 20px;
    /* color: #314d4a; */
    color: #737599;
    font-weight: bold;
    border: none;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    outline: none;
  }

  .selected {
    border-bottom: 3px solid #10d2e1;
    color: #10d2e1;
  }  

  #Meal {
    /* background-color: #f4769e; */
    scroll-margin-top: 10vh;
  }

  #Consumption {
    /* background-color: #a388e8; */
    scroll-margin-top: 10vh;
  }
  
  
  #Subscription {
    /* background-color: #8face0; */
    scroll-margin-top: 10vh;
  }
  
  @keyframes shiny-btn1 {
    0% { -webkit-transform: scale(1) rotate(0deg); opacity: 1; }
    25% { -webkit-transform: scale(1.2) rotate(0deg); opacity: 1; }
    50% { -webkit-transform: scale(1) rotate(0deg); opacity: 1; }
    75% { -webkit-transform: scale(1.2) rotate(0deg); opacity: 1; }
    100% { -webkit-transform: scale(1) rotate(0deg); opacity: 1; } 
    /* 0% { -webkit-transform: scale(1) rotate(0deg); opacity: 1; }
    25% { -webkit-transform: scale(1.2) rotate(30deg); opacity: 1; }
    50% { -webkit-transform: scale(1) rotate(-30deg); opacity: 1; }
    75% { -webkit-transform: scale(1.2) rotate(30deg); opacity: 1; }
    100% { -webkit-transform: scale(1) rotate(0deg); opacity: 1; }  */
    /* 0% { -webkit-transform: scale(1) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(1) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(1) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(1) rotate(45deg); opacity: 0; }  */
  }

  @-webkit-keyframes shiny-btn1 {
    0% { -webkit-transform: scale(1) rotate(0deg); opacity: 1; }
    25% { -webkit-transform: scale(1.2) rotate(30deg); opacity: 1; }
    50% { -webkit-transform: scale(1) rotate(-30deg); opacity: 1; }
    75% { -webkit-transform: scale(1.2) rotate(30deg); opacity: 1; }
    100% { -webkit-transform: scale(1) rotate(0deg); opacity: 1; } 
  }

  .btn-claim-reward {
    position:fixed;
    bottom:1vh;
    left:10%;
    right:10%;
    /* right:2vw; */
    animation: shiny-btn1 2s 3 ease-in-out;
  }


