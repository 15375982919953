
.aggregator-lifestyle .page-content{
    height:90vh;
    overflow:scroll;
    margin-top:10vh;
}

.aggregator-lifestyle{
    overflow: none !important;
}

.aggregator-header{
    width:100vw;
    height:10vh;
    position:fixed;
    top:0;
    z-index: 99999;
    box-shadow: 0px -2px 7px 0px #8e8888;
}

.aggregator-header-list{
    width:100vw;
    height:10vh;
    overflow-x:scroll;
}

.aggregator-header-list li{
    cursor:pointer;
    min-width:120px;
    text-align:center;
    /* border:1px solid #ccc; */
    /* border-radius: 10px; */
    padding:10px;
    /* padding:20px; */
    margin:4px;
}

.aggregator-header-list li.active{
    border-bottom: 2px solid #000;
    /* background-color: #000;
    color: #fff; */
}

.consumption-detail{
    padding:0!important;
    scroll-margin-top: 100px;
}

.consumption-detail .graph-view .nutrition-detail{
    min-height:20vh;
    padding:4px;
    margin:1px;
}

.consumption-detail .report-item-list{
    overflow-x:scroll;
    scroll-behavior: auto;
    overscroll-behavior: auto;
}

.consumption-detail .report-item-list li{
    min-width:100px;
    padding:10px;
    /* padding:20px; */
    margin:4px;
}

.consumption-detail .report-item-list li .nutrition-label{
    font-size:0.9rem;
    text-align: center;
}

.consumption-detail .report-item-list li .nutrition-value{
    font-size:0.75rem;
    text-align: center;
}

::-webkit-scrollbar {
    display: none;
}

.nav-button{
    font-size:1.5rem;
}

.subscription-detail .about-us-list{
    overflow-x:scroll;
}

.subscription-detail .about-us-list li{
    min-width:90%;
    /* padding:10px; */
    /* padding:20px; */
    /* margin:4px; */
}

.subscription-detail{
    padding:0 !important;
    scroll-margin-top: 100px;
}

.meal-list{
    scroll-margin-top: 100px;
}
