.side-menu{
    width:70vw;
    /* background:url('../images/background/1.png') no-repeat; */
    height:100vh;
    background-size: cover;
    
}

.side-menu .heading{
    padding:8px;
    border-bottom:1px solid grey;
}

.side-menu .menu-list-item{
    padding-top:10px;
    padding:10px;
    padding-bottom:10px;
    border-bottom:1px solid #c8c8c870;
    border-radius:0!important;
    font-style:bold;
    padding-top:15px;
    padding-bottom:15px;
    /* box-shadow: 0px 1px 10px 0px #80808094; */
}

.side-menu .menu-list-item p{
    font-weight:600;    
}

.side-menu .btn-close{
    position: absolute;
    bottom: 2%;
    width: 80%;
    left: 10%;
    right: 10%;
}
