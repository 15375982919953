
.suggestion-meal-item .nutrition-detail{
    /* width:85vw; */
    /* overflow-x:scroll; */
    height:70px;
    /* margin-right:20px;
    padding-right:20px; */
    align-items: center;
}

.suggestion-meal-item .nutrition-detail li{
    min-width:80px;
    text-align:center;
}

.suggestion-meal-item .nutrition-detail li .nutrition-label{
    font-size:0.75rem;
    text-align:center;
}

.suggestion-meal-item .nutrition-detail li .nutrition-value{
    font-size:0.8rem;
    text-align:center;
}

.suggestion-meal-item .filter-list{
    width:90vw;
    overflow-x:scroll;
}

.suggestion-meal-item .filter-list li{
    min-width:50px;
    text-align:start;
}

